import { InputAutocompleteAsync } from 'newComponents/Inputs/InputAutocomplete/InputAutocompleteAsync';
import { CONTAINER_TYPE } from 'shared/containers/constants';

export const TypeInputAutocomplete = ({ value, onChange, ...rest }) => {
	const transformOptions = (values) => {
		return Object.values(values).map(({ value, id }) => ({
			label: String(value),
			id: String(id),
		}));
	};

	const options = transformOptions(CONTAINER_TYPE);

	return (
		<InputAutocompleteAsync
			{...rest}
			fetchFn={() => {}}
			value={value}
			placeholder="Выберите или введите вручную"
			options={options}
			onChange={onChange}
		/>
	);
};
