import { useEffect, useMemo } from 'react';
import { useGetTariffByIdQuery } from '../../../../store/newApi/service/service';
import { useSelector } from 'react-redux';

export const useInitFormState = ({ ids, isReady, ...methods }) => {
	const { setValue } = methods;
	const isSingleUpdate = useMemo(() => ids.length === 1, [ids]);
	const filters = useSelector((state) => state['tariffsRtk']?.filter) ?? {};
	const { data } = useGetTariffByIdQuery(
		{ id: ids[0], ...filters },
		{
			skip: !isSingleUpdate,
			refetchOnMountOrArgChange: true,
		},
	);

	useEffect(() => {
		if (data && isReady && isSingleUpdate) {
			if (data.results[0].terminal) {
				setValue('terminal', data.results[0].terminal);
			}
			// if (data.results[0].price_display) {
			// 	setValue('price', data.results[0].price_display.toString());
			// }
			if (data.results[0].unit || data.results[0].unit === 0) {
				setValue('unit', data.results[0].unit);
			}
			if (data.results[0].name) {
				setValue('name', data.results[0].name);
			}
			// if (data.results[0].description) {
			// 	setValue('description', data.results[0].description);
			// }
			if (data.results[0].price_20) {
				setValue('price_20', data.results[0].price_20);
			} 
			if (data.results[0].price_40) {
				setValue('price_40', data.results[0].price_40);
			}
			if (data.results[0].price_45) {
				setValue('price_45', data.results[0].price_45);
			}
		}
	}, [data, setValue, isReady, isSingleUpdate]);
};
