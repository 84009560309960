import { useEffect, useState, useCallback, useMemo } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useGetSummaryTableColumns } from 'shared/ui/Tables/hooks/columns/useGetSummaryTableColumns';
import { useLazyGetContainerRegisterListQuery } from 'store/newApi/containerRegister/containerRegister';
import { Filters } from 'shared/ui/Filters/Filters';
import { FILTER_TYPES } from '../../../../config/filter';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/containerRegister.js';
import { useGetTerminalsListQuery } from 'store/newApi/terminals/terminals';
import { useDispatch } from 'react-redux';
import { isEmpl } from 'shared/auth/isEmpl';
import { Button } from '@mui/material';
import { SummaryForm } from 'newComponents/Form/SummaryForm/SummaryForm';

function AccountEmplSummary() {
	const dispatch = useDispatch();
	const [fetch, { data, isLoading, isFetching, isError }] = useLazyGetContainerRegisterListQuery();

	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});
	const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);

	const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);

	const handleClose = useCallback(() => {
		setRowSelection({});
		setIsCreateFormOpen(false);
	}, [setRowSelection]);

	const handleCreate = useCallback(() => {
		setRowSelection({});
		setIsCreateFormOpen(true);
	}, []);

	const handleUpdate = useCallback(() => {
		setIsCreateFormOpen(true);
	}, []);

	// колонки
	const { columnsList, columnOrdering } = useGetSummaryTableColumns();

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elementsTerminals, setElementsTerminals] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElementsTerminals(newElements);
		}
	}, [dataTerminals]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Отчетности'}
				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'terminal',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elementsTerminals,
							},
							{
								name: 'legal_entity',
								title: 'Юр. лицо',
								type: FILTER_TYPES.INPUTDROPDOWNLEGAL,
							},
							isEmpl() && {
								name: 'client',
								title: 'Клиент',
								type: FILTER_TYPES.INPUTDROPDOWNCLIENT,
							},
							{
								name: 'service',
								title: 'Услуга',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: [
									{ value: 0, label: 'Хранение' },
									{ value: 1, label: 'Ремонт' },
								],
							},
							{
								name: ['date_from_after', 'date_from_before'],
								title: 'Начало расчета',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
							{
								name: ['date_to_after', 'date_to_before'],
								title: 'Окончания расчета',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
							{
								name: ['created_at_after', 'created_at_before'],
								title: 'Дата создания',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
							{
								name: 'manager',
								title: 'Менеджер',
								type: FILTER_TYPES.INPUTDROPDOWNMANAGERS,
							},
							{
								name: 'status',
								title: 'Статус',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 1, label: 'Формируется' },
									{ value: 2, label: 'Создан' },
									{ value: 3, label: 'Отправлен' },
									{ value: 4, label: 'Оплачен' },
									{ value: 5, label: 'Отменен' },
								],
							},
						]}
					/>
				}
				btnDesktop={[
					selectedRows.length > 0 && (
						<Button variant="contained" color="secondary" size="medium" onClick={handleUpdate}>
							Изменить
						</Button>
					),
					<Button variant="contained" color="secondary" size="medium" onClick={handleCreate}>
						Добавить
					</Button>,
				]}
				btnMobile={[
					selectedRows.length > 0 && (
						<Button variant="contained" color="secondary" size="small" onClick={handleUpdate}>
							Изменить
						</Button>
					),
					<Button variant="contained" color="secondary" size="small" onClick={handleCreate}>
						Добавить
					</Button>,
				]}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						fetchData={fetch}
						isLoading={isLoading}
						isError={isError}
						data={data}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						isFetching={isFetching}
						filterName="containerRegisterRtk"
						resetFilters={handleResetFilter}
					/>
					<SummaryForm
						isOpen={isCreateFormOpen}
						handleClose={handleClose}
						entityIds={selectedRows}
						refetchData={() => fetch({ page: 1, page_size: 30 })}
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplSummary;
