import { useEffect, useMemo } from "react"
import { useGetContainerByIdQuery } from "../../../../store/newApi/containers/containers"

export const useInitFormState = ({ ids, isReady, ...methods }) => {
    const { setValue } = methods;
    const isSingleUpdate = useMemo(() => ids.length === 1, [ids]);
    const { data } = useGetContainerByIdQuery({id: ids[0]}, {
        skip: !isSingleUpdate,
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        if (data && isReady && isSingleUpdate) {
            if (data.line) setValue('line', data.line);
            if (data.container_number) setValue('containerNumber', `${data.container_number} ${data.stock ?? ''}`);
            if (data.size) setValue('size', `${data.size}`);
            if (data.type) setValue('type', `${data.type}`);
            if (data.client) setValue('client', data.client);
            if (data.booking) setValue('bookingNumber', data.booking);
            setValue('isFilled', data.is_filled);
            setValue('isDangerous', data.is_dangerous);
            setValue('isDamaged', data.is_damaged);
            setValue('isBlocked', data.is_blocked);
            if (data.terminal) setValue('terminal', data.terminal);
            if (data.iso_code) setValue('isoCode', data.iso_code);
            if (data.location) setValue('location', data.location);
			if (data.stock) setValue('stock', data.stock);
        }
    }, [data, setValue, isReady, isSingleUpdate]);
}