import React, { useEffect, useState } from 'react';
import { Button, Drawer, Box, IconButton, Typography, Stack } from '@mui/material';
import CheckBoxGroup from './components/CheckBoxGroup';
import { getElementsByFilterName, getInitialState } from './utils.js';
import { FILTER_TYPES } from '../../../config/filter.js';
import { filter } from './Filters.styles.js';
import SwithGroup from './components/SwitchGroup/SwitchGroup.jsx';
import InputRangeGroup from './components/InputRangeGroup/InputRangeGroup.jsx';
import InputDropdownGroup from './components/InputDropdownGroup/InputDropdownGroup.jsx';
import InputRangeDateGroup from './components/InputRangeDateGroup/InputRangeDateGroup.jsx';
import InputDropdownGroupClient from './components/InputDropdownGroupClient/InputDropdownGroupClient.jsx';
import { InputDropdownGroupLegalEntities } from './components/InputDropdownGroupLegalEntities/InputDropdownGroupLegalEntities';
import { InputDropdownGroupManagers } from './components/InputDropdownGroupManagers/InputDropdownGroupInputDropdownGroupManagers';

type FilterModalProps = {
	parameters: any;
	onSetFilter: (updatedState: any) => void;
	onResetFilter: () => void;
	isDrawerOpen: boolean;
	setIsDrawerOpen: (open: boolean) => void;
	filterState: any;
	setFilterState: any;
};

export const FilterModal = ({
	parameters,
	onSetFilter,
	onResetFilter,
	isDrawerOpen,
	setIsDrawerOpen,
	filterState,
	setFilterState,
}: FilterModalProps) => {
	useEffect(() => {
		return () => {
			onResetFilter();
		};
	}, []);

	const toggleDrawer = (open) => {
		setIsDrawerOpen(open);
	};

	const handleChange = (name, value) => {
		setFilterState((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	const handleFilter = () => {
		setFilterState((prevState) => {
			const updatedState = { ...prevState };

			for (let key in updatedState) {
				if ((key === 'is_damaged' || key === 'is_dangerous' || key === 'is_filled') && updatedState[key] === false) {
					delete updatedState[key];
				}
			}

			onSetFilter(updatedState);
			return updatedState;
		});

		setIsDrawerOpen(false);
	};

	const handleResetFilter = () => {
		setFilterState(getInitialState(parameters));
		onResetFilter();
		setIsDrawerOpen(false);
	};

	return (
		<Drawer sx={filter.drawer} anchor="right" open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
			<Box sx={filter.content}>
				<Stack justifyContent="space-between" height={1}>
					<Box sx={{ padding: '16px 8px 8px 16px' }}>
						<Typography variant="subtitle1" marginBottom={2}>
							Фильтр
						</Typography>

						{parameters.map((parameter) => {
							switch (parameter.type) {
								case FILTER_TYPES.CHECKBOX:
									return (
										<CheckBoxGroup
											key={parameter.name}
											name={parameter.name}
											title={parameter.title}
											elements={getElementsByFilterName(parameters, parameter.name)}
											state={filterState[parameter.name]}
											onChange={handleChange}
										/>
									);
								case FILTER_TYPES.INPUTNUMRANGE:
									return (
										<InputRangeGroup
											key={parameter.name}
											name={parameter.name}
											text={parameter.text}
											state={[filterState[parameter.name[0]], filterState[parameter.name[1]]]}
											title={parameter.title}
											onChange={handleChange}
										/>
									);
								case FILTER_TYPES.INPUTDATERANGE:
									return (
										<InputRangeDateGroup
											key={parameter.name}
											name={parameter.name}
											state={[filterState[parameter.name[0]], filterState[parameter.name[1]]]}
											title={parameter.title}
											onChange={handleChange}
										/>
									);
								case FILTER_TYPES.SWITCH:
									return (
										<SwithGroup
											key={parameter.name}
											name={parameter.name}
											title={parameter.title}
											elements={getElementsByFilterName(parameters, parameter.name)}
											state={filterState}
											onChange={handleChange}
										/>
									);
								case FILTER_TYPES.INPUTDROPDOWN:
									return (
										<InputDropdownGroup
											key={parameter.name}
											name={parameter.name}
											state={filterState[parameter.name]}
											title={parameter.title}
											onChange={handleChange}
											elements={parameter.elements}
										/>
									);
								case FILTER_TYPES.INPUTDROPDOWNCLIENT:
									return (
										<InputDropdownGroupClient
											key={parameter.name}
											name={parameter.name}
											state={filterState[parameter.name]}
											title={parameter.title}
											onChange={handleChange}
										/>
									);
								case FILTER_TYPES.INPUTDROPDOWNLEGAL:
									return (
										<InputDropdownGroupLegalEntities
											key={parameter.name}
											name={parameter.name}
											state={filterState[parameter.name]}
											title={parameter.title}
											onChange={handleChange}
										/>
									);
								case FILTER_TYPES.INPUTDROPDOWNMANAGERS:
									return (
										<InputDropdownGroupManagers
											key={parameter.name}
											name={parameter.name}
											state={filterState[parameter.name]}
											title={parameter.title}
											onChange={handleChange}
										/>
									);
								// сase - остальные типы фильтров
								default:
									return <></>;
							}
						})}
					</Box>

					<Box sx={filter.button}>
						<Button onClick={handleFilter} variant="contained" color="primary">
							Применить фильтр
						</Button>
						<Button onClick={handleResetFilter} variant="outlined" color="primary">
							Сбросить
						</Button>
					</Box>
				</Stack>
			</Box>
		</Drawer>
	);
};
