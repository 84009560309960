import { Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import InputText from '../../Inputs/InputText/InputText';
import { DrawerModal } from '../../DrawerModal/DrawerModal';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormWrapper } from '../FormWrapper';
import { TerminalInputAutocompleteController } from '../Fields/TerminalInputAutocompleteController';
import { useHandleCreate } from './hooks/useHandleCreate';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { useInitFormState } from './hooks/useInitFormState';
import { UnitInputAutocompleteController } from '../Fields/UnitInputAutocompleteController';

const createSchema = z.object({
	terminal: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	// description: z.string().optional(),
	// price: z.string().optional(),
	unit: z.number().optional(),
	price_20: z.string().optional(),
	price_40: z.string().optional(),
	price_45: z.string().optional(),
});

const updateSchema = z.object({
	terminal: z.number().optional(),
	name: z.string().optional(),
	// description: z.string().optional(),
	// price: z.string().optional(),
	unit: z.number().optional(),
	price_20: z.string().optional(),
	price_40: z.string().optional(),
	price_45: z.string().optional(),
});

export const TariffsForm = ({ isOpen, refetchData, handleClose, entityIds = [] }) => {
	const isCreate = useMemo(() => entityIds.length === 0, [entityIds]);

	const resolver = useMemo(() => (isCreate ? zodResolver(createSchema) : zodResolver(updateSchema)), [isCreate]);

	const methods = useForm({
		defaultValues: {
			terminal: undefined,
			name: '',
			// description: '',
			// price: '',
			unit: undefined,
			price_20: '',
			price_40: '',
			price_45: '',
		},
		resolver: resolver,
	});

	const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods, refetchData, handleClose });
	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, refetchData, entityIds, handleClose });
	useInitFormState({ ...methods, ids: entityIds, isReady: isOpen });

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = methods;

	const onSubmit = useCallback(
		(value) => {
			if (isCreate) handleCreate(value);
			else handleUpdate(value);
		},
		[handleCreate, handleUpdate, isCreate],
	); 
	
	return (
		<DrawerModal
			isOpen={isOpen}
			handleClose={handleClose}
			actions={
				<Button isLoading={isCreateLoading || isUpdateLoading} onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
					Сохранить
				</Button>
			}
		>
			<Typography variant="subtitle1">{isCreate ? 'Добавление' : 'Редактирование'}</Typography>
			<FormWrapper onSubmit={onSubmit} {...methods}>
				<TerminalInputAutocompleteController />
				<CollapseBlock title="Название" defaultOpen={true || !!errors?.name}>
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<>
								<InputText {...field} placeholder="Название" />
								{errors?.name && (
									<Typography color="error" variant="caption">
										{errors?.name?.message}
									</Typography>
								)}
							</>
						)}
					/>
				</CollapseBlock>
				<UnitInputAutocompleteController />
				<CollapseBlock title="Размер 20" defaultOpen={true || !!errors?.price_20}>
					<Controller
						name="price_20"
						control={control}
						render={({ field }) => (
							<>
								<InputText {...field} type="number" placeholder="Введите" />
								{errors?.price_20 && (
									<Typography color="error" variant="caption">
										{errors?.price_20?.message}
									</Typography>
								)}
							</>
						)}
					/>
				</CollapseBlock>
				<CollapseBlock title="Размер 40" defaultOpen={true || !!errors?.price_40}>
					<Controller
						name="price_40"
						control={control}
						render={({ field }) => (
							<>
								<InputText {...field} type="number" placeholder="Введите" />
								{errors?.price_40 && (
									<Typography color="error" variant="caption">
										{errors?.price_40?.message}
									</Typography>
								)}
							</>
						)}
					/>
				</CollapseBlock>
				<CollapseBlock title="Размер 45" defaultOpen={true || !!errors?.price_45}>
					<Controller
						name="price_45"
						control={control}
						render={({ field }) => (
							<>
								<InputText {...field} type="number" placeholder="Введите" />
								{errors?.price_45 && (
									<Typography color="error" variant="caption">
										{errors?.price_45?.message}
									</Typography>
								)}
							</>
						)}
					/>
				</CollapseBlock>
				{/* <CollapseBlock title="Описание">
					<Controller
						name="description"
						control={control}
						render={({ field }) => <InputText {...field} placeholder="Описание" />}
					/>
				</CollapseBlock> */}
				{/* <CollapseBlock title="Цена, ₽" defaultOpen={true || !!errors?.price}>
					<Controller
						name="price"
						control={control}
						render={({ field }) => (
							<>
								<InputText {...field} type="number" placeholder="Цена, ₽" />
								{errors?.price && (
									<Typography color="error" variant="caption">
										{errors?.price?.message}
									</Typography>
								)}
							</>
						)}
					/>
				</CollapseBlock> */}
			</FormWrapper>
		</DrawerModal>
	);
};
