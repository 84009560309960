import { Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import { DrawerModal } from '../../DrawerModal/DrawerModal';
import { FormWrapper } from '../FormWrapper';
import { TerminalInputAutocompleteController } from '../Fields/TerminalInputAutocompleteController';
import { useHandleCreate } from './hooks/useHandleCreate';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import InputRangeDateGroup from 'shared/ui/Filters/components/InputRangeDateGroup/InputRangeDateGroup';
import { InputAutocomplete } from 'newComponents/Inputs/InputAutocomplete/InputAutocomplete';
import { ClientInputMultiAutocomplete } from '../Fields/ClientInputMultiAutocomplete';

export const SummaryForm = ({
	isOpen,
	handleClose,
	refetchData = () => {},
	entityIds = [],
	notEditableValues = {},
	createdCallback = (containerId) => {},
}) => {
	const isSingleUpdate = useMemo(() => entityIds.length === 1, [entityIds]);
	const isBulkUpdate = useMemo(() => entityIds.length > 1, [entityIds]);
	const isCreate = useMemo(() => entityIds.length === 0, [entityIds]);

	// const { resolver } = useGetFormSchema({ isCreate, isImport });

	const methods = useForm({
		defaultValues: {
			clients: [],
			service: undefined,
			terminal: undefined,
			status: undefined,
			dateFrom: '',
			dateTo: '',
		},
		// resolver: resolver,
	});
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors },
	} = methods;

	const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods, handleClose, refetchData, createdCallback });
	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, entityIds, handleClose, refetchData });

	const terminalValue = watch('terminal');

	const onSubmit = useCallback(
		(value) => {
			if (isCreate) handleCreate(value);
			else handleUpdate(value);
		},
		[handleCreate, handleUpdate, isCreate],
	);

	const renderActionBtn = useMemo(() => {
		return (
			// @ts-ignore
			<Button isLoading={isCreateLoading || isUpdateLoading} onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
				Сохранить
			</Button>
		);
	}, [isCreateLoading, isUpdateLoading, handleSubmit, onSubmit]);

	const handleChangeRange = (name, value) => {
		setValue(name, value);
	};

	return (
		<>
			<DrawerModal isOpen={isOpen} handleClose={handleClose} actions={renderActionBtn}>
				<Typography variant="subtitle1">{isCreate ? 'Добавление' : 'Редактирование'}</Typography>
				<FormWrapper onSubmit={() => {}} {...methods}>
					{isCreate && (
						<>
							{!notEditableValues['terminal'] && <TerminalInputAutocompleteController />}
							<CollapseBlock title="Клиенты" defaultOpen={true || !!errors?.clients}>
								<Controller
									name="clients"
									control={control}
									render={({ field }) => <ClientInputMultiAutocomplete {...field} selectedValues={field.value} />}
								/>
							</CollapseBlock>
							<CollapseBlock title="Услуга" defaultOpen={true || !!errors?.service}>
								<Controller
									name="service"
									control={control}
									render={({ field }) => {
										console.log(field.value);
										return (
											<>
												{/* @ts-ignore */}
												<InputAutocomplete
													{...field}
													options={[
														{ label: 'Хранение', id: 0 },
														{ label: 'Ремонт', id: 1 },
													]}
													value={field.value}
													onChange={(newValue) => field.onChange(newValue)}
												/>
												{errors?.service && (
													<Typography color="error" variant="caption">
														{/* @ts-ignore */}
														{errors?.service?.message}
													</Typography>
												)}
											</>
										);
									}}
								/>
							</CollapseBlock>
							<InputRangeDateGroup
								name={['dateFrom', 'dateTo']}
								state={[]}
								title={'Промежуток'}
								onChange={handleChangeRange}
								defaultOpened={true}
							/>
						</>
					)}
					{!isCreate && (
						<CollapseBlock title="Статус" defaultOpen={true || !!errors?.service}>
							<Controller
								name="status"
								control={control}
								render={({ field }) => (
									<>
										{/* @ts-ignore */}
										<InputAutocomplete
											{...field}
											options={[
												{ label: 'Формируется', id: 0 },
												{ label: 'Создан', id: 1 },
												{ label: 'Отправлен', id: 2 },
												{ label: 'Оплачен', id: 3 },
												{ label: 'Отменен', id: 4 },
											]}
											value={field.value}
											onChange={(newValue) => field.onChange(newValue)}
										/>
										{errors?.service && (
											<Typography color="error" variant="caption">
												{/* @ts-ignore */}
												{errors?.service?.message}
											</Typography>
										)}
									</>
								)}
							/>
						</CollapseBlock>
					)}
				</FormWrapper>
			</DrawerModal>
		</>
	);
};
