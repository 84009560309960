import { useEffect, useState, forwardRef } from 'react';
import { Autocomplete, TextField, Box, InputAdornment } from '@mui/material';
import './UnnemberedDescrInputAutoComplete.scss';
import { ArrowForwardIos } from '@mui/icons-material';
import { useLazyGetIsoCodesListQuery } from 'store/newApi/isoCodes/isoCodes';
import { GetIsoCodesListResponse } from 'shared/isoCodes/types';

type UnnemberedDescrInputAutoCompleteProps = {
	value: any;
	onChange: (value: any) => void;
};

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
	return (
		<div className="autocomplete-popper" {...props}>
			{/* @ts-ignore */}
			<ul {...props} ref={ref} />
		</div>
	);
});

export const UnnemberedDescrInputAutoComplete = ({ value, onChange }: UnnemberedDescrInputAutoCompleteProps) => {
	const [fetchIsoCodes, { data = {} as GetIsoCodesListResponse, isLoading, isSuccess }] = useLazyGetIsoCodesListQuery();
	const [options, setOptions] = useState([]);
	const [selectedUnnemberedDescr, setSelectedUnnemberedDescr] = useState(null);
	const [inputValue, setInputValue] = useState('');
	const [openList, setOpenList] = useState(false);

	useEffect(() => {
		fetchIsoCodes({ page: 1, page_size: 30 });
	}, [fetchIsoCodes]);

	useEffect(() => {
		if (isSuccess) {
			setOptions(data.results);
		}
	}, [isSuccess, data]);

	useEffect(() => {
		if (value && options.length) {
			const matchingOption = options.find((option) => `${option.container_size} ${option.container_type}` === value);

			if (matchingOption !== selectedUnnemberedDescr) {
				setSelectedUnnemberedDescr(matchingOption || null);
			}

			if (value !== inputValue) {
				setInputValue(value);
			}
		}
	}, [value, options, selectedUnnemberedDescr, inputValue]);

	const handleInputChange = (event, newInputValue) => {
		setInputValue(newInputValue);
	};

	useEffect(() => {
		if (inputValue) {
			fetchIsoCodes({ page: 1, page_size: 30, search: inputValue });
		} else {
			fetchIsoCodes({ page: 1, page_size: 30 });
		}
	}, [inputValue]);

	const handleOptionChange = (event, newValue) => {
		setSelectedUnnemberedDescr(newValue);
		onChange(newValue ? `${newValue.container_size} ${newValue.container_type}` : '');
	};

	const handleInputClick = () => {
		setOpenList(true);
	};

	const handleCloseList = () => {
		setOpenList(false);
	};

	return (
		<div className="autocomplete-wrapper">
			<Autocomplete
				multiple={false}
				value={selectedUnnemberedDescr}
				onChange={handleOptionChange}
				inputValue={inputValue}
				onInputChange={handleInputChange}
				open={openList}
				onOpen={handleInputClick}
				onClose={handleCloseList}
				getOptionLabel={(option) => (option ? `${option.container_size} ${option.container_type}` : '')}
				options={options}
				loading={isLoading}
				loadingText={<Box className="loading-text">Загрузка...</Box>}
				noOptionsText={openList && isSuccess && options.length === 0 && <Box sx={{ padding: '8px' }}>Не найдено</Box>}
				PopperComponent={ListboxComponent}
				renderOption={(props, option) => <li {...props} key={option.id} >{`${option.container_size} ${option.container_type}`}</li>}
				renderInput={(params) => (
					<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '2px' }}>
						<span>Описание</span>
						<TextField
							{...params}
							className={selectedUnnemberedDescr ? 'autocomplete-input selected' : 'autocomplete-input'}
							placeholder="Выберите"
							onClick={handleInputClick}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<InputAdornment position="end">
										<ArrowForwardIos
											style={{
												width: 16,
												height: 16,
												// @ts-ignore
												color: (theme) => theme.palette.text.secondary,
												transform: openList ? 'rotate(90deg)' : 'rotate(0deg)',
												transition: 'transform 0.3s ease',
												cursor: 'pointer',
											}}
										/>
									</InputAdornment>
								),
							}}
						/>
					</Box>
				)}
			/>
		</div>
	);
};
