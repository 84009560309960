import React, { useEffect, useMemo, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import { useParams } from 'react-router-dom';
import Header from '../../../../newComponents/Header/Header';
import { useLazyGetEntryQuery, useUpdateEntryMutation } from '../../../../store/newApi/entries/entries';
import { Box, Button, IconButton } from '@mui/material';
import { Preloader } from '../../../../components';
import { account } from './Account.styles.js';
import { EntriesForm } from '../../../../newComponents/Form/EntriesForm/EntriesForm.jsx';
import { isEmpl } from '../../../../shared/auth/isEmpl';
import { usePrintDocMutation } from '../../../../store/newApi/print/print';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';
import { IconPlus } from 'components/Icons';
import InputText from 'newComponents/Inputs/InputText/InputText';

const Counter = ({ quantityEntries, setQuantityEntries }) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '8px',
			}}
		>
			<IconButton color="primary" size="small" onClick={() => setQuantityEntries((prev) => Math.max(1, Number(prev) - 1))}>
				-
			</IconButton>
			<div style={{ maxWidth: '65px' }}>
				<InputText
					placeholder="1"
					type="number"
					value={quantityEntries}
					onChange={(e) => setQuantityEntries(e.target.value)}
				/>
			</div>
			<IconButton color="primary" size="small" onClick={() => setQuantityEntries((prev) => Number(prev) + 1)}>
				<IconPlus color={'white'} size={'small'} />
			</IconButton>
		</div>
	);
};

function AccountEmplEntryPage() {
	const { showNotificaton } = useNotify();
	let { orderId, entryId } = useParams();
	const [trigger, { data, isLoading }] = useLazyGetEntryQuery({});
	const [updateEntry] = useUpdateEntryMutation();
	const [printDoc, { isLoading: isLoadingPrint }] = usePrintDocMutation();
	const [selectContainer, setSelectContainer] = useState(false);
	const [selectOrder, setSelectOrder] = useState(false);
	const [quantityEntries, setQuantityEntries] = useState(null);

	useEffect(() => {
		if (!selectContainer) {
			setQuantityEntries(1);
		} else {
			setQuantityEntries(0);
		}
	}, [selectContainer, setQuantityEntries]);

	useEffect(() => {
		if (entryId) {
			trigger({ entryId });
		}
	}, [entryId]);

	const handleChangeStatus = () => {
		updateEntry({ pass_pk: entryId, status: 1 }).then((data) => {
			if (data.error) {
				showNotificaton({
					type: 'error',
					message: tranformErrorsToRender(data.error?.data, 'Не удалось использовать пропуск'),
				});
			} else {
				trigger({ entryId });
				showNotificaton({
					type: 'success',
					message: 'Пропуск успешно использован',
				});
			}
		});
	};

	const getHeaderBtns = useMemo(() => {
		let btns = [];
		if (data) {
			btns.push(
				<Button
					variant="contained"
					color="secondary"
					onClick={() =>
						printDoc({
							template_id: 3,
							instance_id: data?.id,
						})
					}
				>
					Распечатать
				</Button>,
			);
		} else if (!selectContainer && (orderId || selectOrder)) {
			btns.push(<Counter quantityEntries={quantityEntries} setQuantityEntries={setQuantityEntries} />);
		}
		if (isEmpl() && data?.status == 0) {
			btns.push(
				<Button variant="contained" color="primary" onClick={handleChangeStatus}>
					Использовать пропуск
				</Button>,
			);
		} else {
			btns.push(data?.status_display);
		}
		return btns;
	}, [data, selectContainer, quantityEntries, setQuantityEntries, selectOrder]);

	return (
		<>
			{isLoadingPrint && (
				<div
					style={{
						position: 'absolute',
						zIndex: 10000,
						width: '100vw',
						height: '100vh',
						backgroundColor: '#eeea',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{/* @ts-ignore */}
					<Preloader />
				</div>
			)}
			<div className={styles.account__wrap}>
				<Header
					text={isLoading ? '' : `${data ? data?.name : 'Создать пропуск'}`}
					btnDesktop={getHeaderBtns}
					btnMobile={getHeaderBtns}
				/>
				{isLoading ? (
					<Box sx={account.preloader}>
						<Preloader />
					</Box>
				) : (
					<div className={styles.account__container}>
						<div className={styles.account__stack}>
							<EntriesForm
								refetchData={() => trigger({ entryId })}
								isCreate={!entryId}
								data={data}
								setSelectContainer={setSelectContainer}
								quantityEntries={quantityEntries}
								setSelectOrder={setSelectOrder}
							/>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default AccountEmplEntryPage;
